@import 'bootstrap-minimal';
@import 'theme';
@import 'visible';

// @import '~angular-upload/src/directives/btnUpload.min.css';

.row {
    padding: 10px 0;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.inline {
    display: inline-block;
}

.report {
    .table-condensed td:first-child {
        padding-left: 0;
    }

    .table-condensed td:last-child {
        padding-right: 0;
    }

    .table {
        margin-bottom: 0;
    }
}

.panel-group .panel+.panel {
    margin-top: 25px;
}

.panel-primary {
    .panel {
        margin: 0 5px 5px 5px;
    }

    & > .panel-body {
        background-color: #337ab7;
    }

    & > .panel-footer {
        background-color: #337ab7;
        color: white;
    }
} 

.panel-info {
    .panel-body {
        background-color: #d9edf7;
    }

    .panel-body.question {
        background-color: white;
    }
} 

.question {
    margin: 0 5px 5px 5px;
}

#validationError {
    margin-top: 5px;
}

.alert.alert-default {
    background-color: white;
}

.dropdown-menu {
    padding: 0;
    min-width: 0;
}

.top-panel-heading-color {
    color: white;
}

#forgottenFormGroup {
    margin: 5px;

    a {
        cursor: pointer;
    }
}

// delete after switched to angular material
.btn.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

// new design
html {
    font-size: 16px;
}

body {
    font-size: 1em;
}

html, body {
    height: 100%;
    margin: 0;
    font-family: 'Work Sans', sans-serif;
    background-color: $color-background;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
    line-height: 22px;
}

h6 {
    font-size: 12px;
}

h4.page-title {
    margin-top: $space-lg * 2;
    margin-bottom: $space-lg;
}

.actions button {
    margin-right: 5px;
}

div[id^=__lpform_] {
    display: none !important;
}

.form-container {
    display: flex;
    flex-direction: column;
}

.shadow {
    box-shadow: 0 2px 5px 0px rgba(0,0,0,0.26);
}

.panel {
    padding: 0 $space-lg;
}

.primary-bg {
    background-color: $color-primary;
}


control-error {
    // color:#ff3a5f;

    .hide {
        display: none;
    }
}

.actions {
    button {
        margin-right: $space-sm*2;
        margin-left: $space-sm*2;
        margin-bottom: $space-sm;
    }

    .button-panel {
        display: inline-flex;
        flex-direction: column;

        .mat-mdc-form-field-hint {
            font-size: 10px;
        }
    }
}


.text-uppercase {
    text-transform: uppercase;
}

app-toggle-button.light-icon {
    .mat-icon {
        color: $color-header-text;
    }
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    line-height: 20px;

    a {color: black}
}

.alert-danger {
    border-color: #f5c6cb;
    background-color: #f8d7da;
    color: #721c24;
}

.uploader {
    .dragNDrop .div1 {
        width: 100% !important;
    }

    .container {
        padding-bottom: $space-sm;
    }

    .btn {
        box-sizing: border-box;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        outline: none;
        border: none;
        -webkit-tap-highlight-color: transparent;
        display: inline-block;
        white-space: nowrap;
        text-decoration: none;
        vertical-align: baseline;
        text-align: center;
        margin: 0;
        min-width: 64px;
        line-height: 36px;
        padding: 0 16px;
        border-radius: 4px;
        overflow: visible;
        
        font-family: "Work Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: white;

        &[disabled] {
            cursor: not-allowed;
            background-color: #d5d5d5 !important;
        }

        &.btn-success {
            background-color: $color-primary;
        }

        &.btn-info {
            background-color: $color-info;
        }
    }

    .afu-valid-file {
        padding-left: 15px;
    }

    .afu-upload-status {
        text-align: center;

        &.text-danger {
            color: $color-danger;
        }

        &.text-success {
            color: $color-success;
        }
    }
    
}

div.container {
    padding-bottom: $space-xl;
}

.width-max {
    width: 100%;
}

.cc-window {
    border: 1px solid $color-border;
}

ul.simple {    
    margin: 0;
    list-style: none;
    padding-left: 0;

    .mat-icon {
        margin-right: 20px;
    }    
}

.empty-text {
    color: $color-placeholder-text;
}

.no-click {
    cursor: default;
}
