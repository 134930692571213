.mat-mdc-tab-group.full-height {
    height: 100%;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-body-wrapper {
        height: 100%;
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        mat-tab-body {
            height: 100%;
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            .mat-tab-body-content{
                height: 100%;
            }
        }
    }
}