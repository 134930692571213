// Bootstrap parts
html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }
  
*,
*::before,
*::after {
    box-sizing: inherit;
}

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

@import "bootstrap/scss/mixins/breakpoints";
@import 'bootstrap/scss/mixins/hover';
@import 'bootstrap/scss/mixins/table-row';
@import "bootstrap/scss/mixins/grid-framework";
@import "bootstrap/scss/mixins/grid";

@import "bootstrap/scss/grid";
@import 'bootstrap/scss/tables';

@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/utilities/flex";
@import "bootstrap/scss/utilities/spacing";




