// colors:
$color-alt1: #17A5E3;
$color-alt2: #335AD6;
$color-alt3: #433696;

$color-primary: #161041;
$color-secondary: $color-alt1;

$color-success: #2B9443;
$color-warning: #F09443;
$color-info: $color-alt1;
$color-danger: #D94646;

$color-inverted: #ffffff;

$color-light1: #EFF2F6;
$color-light2: #C7D1E6;

$color-page-subtitle: #9491a7;

// component colors:
$color-background: #e1e0e7;
$color-header-bk: $color-primary;
$color-header-text: #eeeeee;
$color-border: #cccccc;
$color-border-50: #eeeeee;

$color-placeholder-text: $color-border;
$color-text: rgba(0, 0, 0, 0.87);
$color-text-alt: rgba(0, 0, 0, 0.67);
$color-text-alt2: #ADADAD;
$color-text-inactive: #d8d8d8;

$color-progress-bar: #2384dd;
$color-progress-bar-bg: #454067;

$color-report-header-bk: #edebf5;

$color-info2: #338dcd;

.bg-danger {
    background-color: $color-danger;
}

.bg-info {
    background-color: $color-info;
}

.bg-success {
    background-color: $color-success;
}

.bg-warning {
    background-color: $color-warning;
}

.text-warning {
    color: $color-warning;
}

.text-info {
    color: $color-info;
}

.text-info2 {
    color: $color-info2;
}

.text-alt1 {
    color: $color-alt1;
}

.text-alt2 {
    color: $color-alt2;
}

.text-danger {
    color: $color-danger;
}

.text-success {
    color: $color-success;
}

.font-weight-500 {
    font-weight: 500;
}

.text-subtitle{
    color: $color-page-subtitle
}

.material-icons-two-tone {
    filter: invert(0.5) sepia(1) saturate(10) hue-rotate(180deg);
}