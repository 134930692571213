@import 'include-media';

.table-default {
    width: 100%;
}

.table-hover {
    tbody {
        tr.mat-mdc-row:hover {
            td {
                background-color: $color-secondary;
                color: rgba(255,255,255,0.8);
                //background-color: $color-secondary !important;
                a:hover, button:hover {
                    color: white;
                }
                // &.dark-hover-text {
                //     color: var(--mat-table-row-item-label-text-color);
                // }
            }
        }
        tr.mat-mdc-header-row {
            background-color: transparent;            
        }

        tr.detail-row:hover {
            background-color: transparent;
            td {
                color: rgba(0,0,0,0.8);
            }
        }
    }

    &.hover-pointer {
        cursor: pointer;
        tr.no-pointer {
            cursor: default;
        }
    }
}



@include media("<768px") {
    .table-default {
            
        td.mat-mdc-cell:first-of-type {
            padding-left: 8px;
        }

        td.mat-mdc-cell:last-of-type {
            padding-right: 8px;
        }
    }
}

@include media("<640px", 'screen') { 
    
    .mat-mdc-table {
        &.table-and-card {
            background: transparent;
            *:not(.mdc-checkbox) {
              box-sizing: border-box;
            }
    
            .mat-mdc-header-row {
                display: none;
            }
    
            tbody {
                display: block;
                width: 100%;
              }
        
            .mat-mdc-row {
              display: flex;
              flex-wrap: wrap;
              height: auto;
              position: relative;
              padding: 8px 0;
              clear: both;
              border-bottom: 1px solid #cccccc;
              background-color: #fff;
            }
        
            .mat-mdc-cell {
                
                padding: 0 16px;
                margin: 8px 0;
                border: 0 none;

                &.m-card-sub-title {
                    margin-top: -8px;
                    padding: 0 48px 0 16px;
                    color: rgba(0,0,0,.54);
                }
            }
        
            .has_label_on_mobile {
              &:before {
                content: attr(data-label);
                display: inline;
                font-weight: normal;
              }
            }
        
            .col-actions {
                display: flex;
                justify-content: flex-end;
            }

            .mat-mdc-column-star {
              width: auto;
              padding: 8px 0 0 !important;
              margin: 0;
              position: absolute;
              top: 0;
              right: 0;
              &:before {
                display: none;
              }
            }
        }
    }
}