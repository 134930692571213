@use 'colors';
@use 'fonts';


.mat-mdc-form-field {
    &.no-label {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        border-top: 0;
      }
    }
  
    &.wrap-label-text {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-label {
        mat-label {
          white-space: normal;
        }
      }
    }

    &.label-color-secondary {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-label {
        color: $color-secondary;
      }
    }

    &.bk-blade {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex {
        background-image: url('/assets/img/blade-3-op15.png');
       }
    }

    &.mat-fill-white.mat-form-field-appearance-fill {
       /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
       .mat-form-field-flex {
        background-color: #ffffff;
       }
    }

    &.style-2 {
      &.mat-form-field-appearance-fill {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-flex {
          background-color: #ececec;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-label {
        color: $color-secondary;
      }
    }

    &.flex-field-horizontal {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        width: auto;
      }
    }

    // lastpass image hide fix
    input {
      background-image: none !important;
    }

    &.field-simple {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex {
        padding-top: 0;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        border-top: 0;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-underline {
        bottom: 0;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }
      
    }

    &.input-lg {      
      input {
        @extend .text-lg;
      }
    }

    &.full-width {
      width: 100%;
      &.mat-form-field-type-mat-date-range-input {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
          width: 100%;
        }
      }
    }
}

.label-color-secondary {
  .mat-mdc-form-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
      color: $color-secondary;
    }
  }
}

.mat-mdc-checkbox {
  &.multiline {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
      white-space: inherit;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-inner-container {
      margin-top: 5px;
    }
  }

  &.label-wrap {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-label {
      white-space: normal;
    }
  }

  &.label-lh-18 {
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-label {
      line-height: 18px;
    }
  }
}

input.mat-mdc-input-element {
  &.no-bkg {          
      -webkit-box-shadow:0 0 0 50px #f5f5f5 inset;
  }
}

  
