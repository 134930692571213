@import 'include-media';

.mat-mdc-card-subtitle,.mat-mdc-card-content {
  @extend .text-sm;
}

.mat-mdc-card {
    &.card-light {
      padding: $space-lg;
  
      & > .mat-mdc-card-header {
        padding: 0 16px;
        color: #000000;
      }
  
      & > .mat-mdc-card-content {
        padding: $space-m;        
      }
      
      &.padding-m {
        padding: $space-m;
      }

      .mat-mdc-card-actions {
          display: flex;
          justify-content: flex-end;
          button {
              margin-left: 10px;
          }
      }
    }
  
    &.card-light-secondary {
      background-color: $color-background;    
      margin-bottom: $space-sm;
    }
    
    &.card-dark {
      background-color: $color-primary;
      padding: 6px;
  
      & > .mat-mdc-card-header {
        color: $color-header-text;
        padding: $space-sm;
      }
  
      margin-bottom: $space-lg;
    }           
  
    &.question {
           
      .mat-mdc-card-title {
        color: $color-primary;
        font-size: 21px;
        font-weight: 800;
        line-height: 28px;
      }     

      & > .mat-mdc-card-content {
        padding: 0;
        // .mat-radio-button {
        //   margin-right: 7px;
        // }
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label {
          align-items: flex-start;
          white-space: inherit;
        }
      }
      margin: 0 16px 12px 16px;
      margin-bottom: 24px;
    }
   
    &.card-group {
      background-image: url('/assets/img/blade-3-op15.png');
      margin: 64px 16px 48px 16px;

      .group-text {
        color: $color-primary;
        p {
            display: inline;
            margin-top: 0;            
            font-weight: 800;

            &.text-18 {
                font-size: 18px;
            }
        }

        &.group-level-1 p:first-of-type {
          font-size: 30px;
          line-height: 38px;
        }

        &.group-level-2 p:first-of-type {
          font-size: 27px;
          line-height: 33px;
        }

        &.group-level-3 p:first-of-type {
          font-size: 24px;
          line-height: 28px;
        }
    
        ul {
            list-style: none;
            padding-left: 0;
            li {
                padding-left: 12px;
                border-left: 2px solid $color-alt2;
                margin-bottom: 10px;
            }
        }
      }

      .mat-mdc-card-content {
        padding: 8px;
        padding-bottom: 0;
      }
    }

    &.card-table {
      padding: 0;

      .mat-mdc-card-header {
        .t-row {
          font-size: 13px;
        }
      }

      .mat-mdc-card-content, .mat-mdc-card-footer {
        margin: 0;
        
      }

      .mat-mdc-card-footer {
        padding: 10px 0;
      }
    }
    
    .mat-mdc-card-header {
      display: block;
    }

    .mat-mdc-card-content {
      &.no-bottom-margin {
        margin-bottom: 0;
      }
    }
    
    // &.card-nested {
    //   &.card-light-secondary {
    //     & > .mat-card-header {
    //       .mat-card-title {
    //         font-size: 16px;
    //       }
    //     }
        
    //     padding: $space-m;
    //   }
  
    //   &.card-light {
    //     & > .mat-card-header {
    //       .mat-card-header-text {
    //         margin: 0;
    //       }
    //       .mat-card-title {
    //         color: $color-primary;
    //         font-size: 14px;
    //       }
    //     }
  
    //     & > .mat-card-content {
    //       padding: 0;
    //     }
        
    //     padding: $space-m;
    //     margin-bottom: $space-m;
    //   }
      
    // }

    &.card-inner-question {
      box-shadow: none;
      background-color: transparent;
      padding: 0;

      & > .mat-mdc-card-header {
        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
        .mat-card-header-text {
          margin: 0;
        }
      }

      .mat-mdc-card-title {
        color: $color-primary;
        font-size: 18px;
      }
    }
      
    &.report {
      padding: 0;      
      
      & > .mat-mdc-card-header {
        padding: 24px 32px;
        border-bottom: 1px solid $color-border-50;
        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
        .mat-card-header-text {
          margin: 0;
        }  
      }

      & > .mat-mdc-card-content {
        padding: 20px;
        padding-top: 0;

        .value-line {          
          b {
            font-weight: 500;            
          }
          
        }

        &.no-content-rules {
          padding: 0;
         }
      }

      & > .mat-mdc-card-footer {
        display: flex;
        margin: 0;
        padding: 10px 15px;
      }
    }

    &.card-review {
      padding: $space-m;
  
      & > .mat-mdc-card-content {
        padding: $space-sm;        
      }
      .mat-mdc-card-content {
        display: flex;
        gap: 16px;
      }
    }
    
  }

  .header-with-status {
    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    .mat-card-header-text {
      flex: 1;
    }

    .card-state {
      padding-right: 50px;
      display: flex;
      align-items: center;
    }
  }


  .card-content-no-vertical-padding {
    .mat-mdc-card {
      .mat-mdc-card-content {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .disabled {
    .mat-mdc-card {
      .mat-mdc-card-header {
        opacity: 0.3;
      }
    }
  }

  /* 1280 */
  @include media("<1280px") {    
      .question-2col-layout {
        .question {
          .mat-mdc-card-title {
            min-height: inherit;
          }
        }
        app-question {
          width: 100%;
          display: block;
  
          // .mat-radio-label {
          //     white-space: inherit;
          // }
        } 
      }      
    
  }

  /* 1024 */

  @include media("<1024px") {
    .mat-mdc-card {
      &.card-light {
        padding: $space-m;
  
      }

      &.card-group {
        .group-text {
          &.group-level-1 p:first-of-type {
            font-size: 24px;
          }
  
          &.group-level-2 p:first-of-type {
            font-size: 21px;
          }
  
          &.group-level-3 p:first-of-type {
            font-size: 18px;
          }
        }
                
      }

      &.question {        
         .mat-mdc-card-title, .line-index {
            font-size: 14px;
            line-height: 20px;
         }
      }
    }
  }
  
  /* 800 */
  
  @include media("<800px") {    

    .mat-mdc-card {      

      &.card-light {                
        padding: 16px;
        padding-left: 8px;
        padding-right: 8px;
        //padding-top: 12px;
  
        & > .mat-mdc-card-actions:last-child {
          margin-bottom: 8px;
          margin-right: 8px;
        }
        
      }

      &.question-level-1 {
        padding: 0;
      }

      &.question {
        margin-left: 0;
        margin-right: 0;
               
        .value-text {
          font-size: 12px;
          min-width: 110px;
        }

        .min-title {
          padding-right: 20px;
        }

        .max-title {
          padding-left: 8px;
        }
      }

      &.card-group {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @include media("<768px") {
    .card-content-no-vertical-padding {
      .mat-mdc-card {
        .mat-mdc-card-content {
          padding-bottom: 12px;
        }
      }
    }

    .question-title-bar {
      img {
        max-width: 380px;
       }
    }

    .mat-mdc-card {
      &.card-light, &.card-review {
        & > .mat-mdc-card-content {
          &.responsive-size {
            padding: 0;
          }
        }
      }

      &.report {
        .mat-mdc-card-content {
          padding-left: 5px;
          padding-right: 5px;
        }
      }

    }
}

@include media("<540px") {
  .mat-mdc-card.report .mat-mdc-card-content {
    padding-left: 0; 
    padding-right: 0;
  }
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }

    .mat-mdc-card {
      &.report {        
        margin: 0;
        box-shadow: none;
        border: none;
        border-radius: 24px;
        
        .mat-mdc-card-content {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    }
    
}