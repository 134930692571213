@media all {
    #report2, .competencyReport {
        -webkit-print-color-adjust: exact !important;
        margin: 10px 25px;
        font-size: 7px;

        .col {
            padding: 0 5px;
        }

        .row {
            padding: 0;
            margin: 0;
            position: relative;
        }

        .color-background {
            background-color: #f5f5f5 !important;
            //color: #fff;
        }

        .italic {
            font-style: italic;
        }

        .medium-font {
            font-size: 8px;
        }

        .large-font {
            font-size: 9px;
        }

        .margin-before {
            margin-top: 15px;
        }

        .margin-after {
            margin-bottom: 15px;
        }

        .margin-right {
            margin-right: 15px;
        }

        .no-padding {
            padding: 0;
        }

        .padding {
            padding: 5px;
        }

        .no-border {
            border-width: 0;
        }

        .border {
            border: 2px solid #999;
        }

        .v-border + .v-border {
            border: 2px solid #999;
            border-width: 1px 0 0px 0;
        }

        .t-border {
            border: 2px solid #999;
            border-width: 1px 0 0px 0;
        }

        .h-border {
            border: 2px solid #999;
            border-width: 0 1px 0 1px;
        }

        .l-border {
            border: 2px solid #999;
            border-width: 0 0 0 1px;
        }

        .strong-border {
            border: 3px solid black;
        }

        .empty-line {
            height: 20px;
        }

        .fixed-height {
            height: 20px;
            display: table;
        }

        .fixed-height-big {
            height: 24px;
            display: table;
        }

        .fixed-height-4 {
            height: 75px;
            display: table;
        }

        span, strong {
            display: table-cell;
            vertical-align: middle;
        }

        .grey {
            color: #999;
            -webkit-print-color-adjust: exact;
        }

        .col-10 {
            width: 10%;
            white-space: nowrap;
        }

        .col-125 {
            width: 12.5%;
        }

        .col-15 {
            width: 14.9%;
            white-space: nowrap;
        }

        .col-175 {
            width: 17.4%;
            white-space: nowrap;
        }


        .col-30 {
            width: 30%;
        }

        .col-40 {
            width: 40%;
        }

        .col-50 {
            width: 50%;
        }

        .col-60 {
            width: 60%;
        }

        .col-70 {
            width: 70%;
        }

        .col-875 {
            width: 87.5%;
        }

        .font-weight-bold {
            font-weight: 700;
        }
    }
}

@media all {
    
    .logo {
        position: absolute;
        top: 0;
        right: 15px;
        opacity: 0.5;
    }

    .competencyReport {
        font-size: 12px;

        .medium-font {
            font-size: 13px;
        }

        .large-font {
            font-size: 14px;
        }

        .color-background {
            background-color: #f5f5f5 !important;
            //color: white !important;
        }
    }
}

@media print 
{
    @page {
        -webkit-print-color-adjust: exact !important;
        margin: 1cm;
        font-size: 8pt;
    }

    body {
        font-size: 8pt;
    }

    #report2, .competencyReport {
        .report-body {
            page-break-after: always;
        }
        .page {
            margin: 1.2cm 0.6cm;
        }
        .alert-danger {
            color: #a94442 !important;
            background-color: #f2dede !important;
        }
    }

    .goals.container {
        width: 100%;
    }

    .goals {
        dynamic-text, tfoot, #newGoal {
            display: none;
        }

        input, textarea, select, .panel, p {
            border: 0;
            height: auto;
        }        

        select {
            -webkit-appearance: none;
        }

        textarea {
            overflow: visible;
            height: auto;
        }

        .collapse {
            display: block;
        }

        .page-break {
            page-break-before: always;
        }

        .btn {
            *, :before {
                color: #fff !important;
            }
        }

        .btn-success {
            background-color: #5cb85c !important;
        }

        .btn-danger {
            background-color: #d9534f !important;
        }

        .btn-warning {
            background-color: #f0ad4e !important;
        }

        .btn-info {
            background-color: #5bc0de !important;
        }

        .btn-default {
            background-color: #fff !important;

            *, :before {
                color: #000 !important;
            }
        }
    }

}