$space-xs: 4px;
$space-sm: 8px;
$space-m: 16px;
$space-lg: 32px;
$space-xl: 48px;
$space-xxl: 96px;

// paddings
.padding-xs {
    padding: $space-xs;
}

.padding-sm {
    padding: $space-sm;
}

.padding-m {
    padding: $space-m;
}

.padding-lg {
    padding: $space-lg;
}

.padding-xl {
    padding: $space-xl;
}

.padding-xxl {
    padding: $space-xxl;
}

// padding right

.padding-right-lg {
    padding-right: $space-lg;
}

// padding bottom

.p-b-lg {
    padding-bottom: $space-lg;
}

.padding-bottom-m {
    padding-bottom: $space-m;
}

.padding-top-lg {
    padding-top: $space-lg;
}

// margins
.margin-xs {
    margin: $space-xs;
}

.margin-sm {
    margin: $space-sm;
}

.margin-m {
    margin: $space-m;
}

.margin-lg {
    margin: $space-lg;
}

.margin-xl {
    margin: $space-xl;
}

.margin-xxl {
    margin: $space-xxl;
}

// margin top

.margin-top-sm {
    margin-top: $space-sm;
}

.margin-top-m {
    margin-top: $space-m;
}

.margin-top-lg {
    margin-top: $space-lg;
}

.margin-top-xl {
    margin-top: $space-xl;
}

// margin bottom

.margin-bottom-sm {
    margin-bottom: $space-sm;
}

.margin-bottom-m {
    margin-bottom: $space-m;
}

.margin-bottom-lg {
    margin-bottom: $space-lg;
}

.margin-bottom-xl {
    margin-bottom: $space-xl;
}

.no-top-margin {    
    margin-top: 0;
}

// height

.height-100 {
    height: 100px;    
}

.height-200 {
    height: 200px;
}

.height-300 {
    height: 300px;
}

// max-height

.max-height-100 {
    max-height: 100px;
}

.max-height-200 {
    max-height: 200px;
}

.max-height-300 {
    max-height: 300px;
}

.padding-left-4 {
    padding-left: 4px;
}