@import 'space';
@import 'colors';
@import 'include-media';
@import 'material/mat-form-field';

body {    
}

.w-full-hd {
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 16px;
}

.flex-vertical, .flex-v {
    display: flex;
    flex-direction: column;
}

.align-items-center {
    align-items: center;
}

.flex-horizontal, .flex-h {
    display: flex;
    flex-direction: row;

    .mat-mdc-form-field {
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
            width: auto;
        }
    }   

    .flex-fill {
        flex: 1;
    }

    .flex1 {
        flex: 1;
    }

    .flex-30 {
        flex: 0 0 30%;
    }
    
    .flex-70 {
        flex: 0 0 70%;
    }

    .flex-55 {
        flex: 0 0 55%;
    }

    .flex-45 {
        flex: 0 0 45%;
    }
}

.flex-h-card-header {
    /*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
    .mat-card-header-text {
        width: 100%;
        display: flex;
    }
}

.justify-content-center {
    justify-content: center;
}

.flex-end, .mat-mdc-dialog-actions.flex-end {
    justify-content: flex-end;
}

.row-buttons {
    display: flex;
    button {
        margin: 0 -5px;
    }
}

.action-buttons {                
    display: flex;
    gap: 12px;

    padding: 10px 20px;
    margin-top: 12px;
}


.input-with-buttons {
    display: flex;

    .mat-mdc-form-field {
        flex: 1;
    }

    .row-buttons {
        align-items: flex-end;
        padding-bottom: 20px;
        padding-left: 5px;
    }
}

.span-lg {
    span {
        @extend .text-lg;
        font-weight: 500;
    }
}

.archive {
    color: $color-text-inactive;
}

.icon-message {
    display: flex;
    flex-direction: row;

    .mat-icon {
        flex: 0 0 24px;
        margin-right: 6px;
    }

    & > div {
        line-height: 24px;
    }
}

.container, .container-fluid {
    &.container-extra {
        max-width: 1400px;
    }

    &.main-container {
        padding-top: 32px;
    }

    &.no-horizontal-padding {
        padding-left: 0;
        padding-right: 0;
    }
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.scroll-panel {
    overflow-y: auto;
    height: 300px;
    margin-right: -24px;

    &.height-medium {
        height: 200px;
    }
}

.scroll-panel-400 {
    overflow-y: auto;
    max-height: 400px;
}

.scroll-box {
    overflow-y: auto;
}

.form-field {
    height: 52px;
}

.margin-top-off {
    margin-top: 0;
}

.margin-top-20 {
    margin-top: 20px;
}

.no-wrap {
    white-space: nowrap;
}

app-question {
    display: block;
}

.display-block {
    display: block;
}

.question-2col-layout {
    app-question {
        width: 50%;
        display: inline-block;
        /*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label {
            white-space: inherit;
        }
        
    }    

    .question {
        .mat-mdc-card-title {
          min-height: 84px;
        }
      }
}

.page-break-before {    
    page-break-before: always;
}

.page-break-after {
    page-break-after: always;
}

.no-bottom-border {
    border-bottom: none;
}

.border-bottom {
    border-bottom: 1px solid #e8e8e8;
}

.hidden {
    display: none;
}

.scroll-box {
    overflow-y: scroll;
    height: 800px;
}

.t-d, .t-h {
    flex: 1;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;            
}

.t-d {
    padding: 10px 5px;
}

.t-h {
    padding: 18px 5px;
}

div.main-table {
    border-collapse: separate;
    border-spacing: 2px;
    .t-row {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        //align-items: center;
        //justify-content: space-between;
    }
    
    .mat-mdc-card-content, .mat-mdc-card-header {
        .t-row:not(.no-bottom-border)
        {
            border-bottom: 1px solid #efefef;
        }        
    }
}

.inverted {
    &.mat-mdc-checkbox {
        --mdc-form-field-label-text-color: $color-inverted;
        &.mat-mdc-checkbox-disabled label {
            color: $color-text-inactive;
        }
    }

    --mdc-checkbox-unselected-icon-color: $color-inverted;
    --mdc-checkbox-unselected-hover-icon-color: $color-inverted;
    --mdc-checkbox-unselected-focus-icon-color: $color-inverted;
}

//template
.mat-mdc-form-field {
    &.field-lg {
        input {
            font-size: 28px;
            line-height: 48px;
            text-align: center;
        }

        &.field-inverted {
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            &.mat-form-field-appearance-fill .mat-form-field-flex {
                color: $color-inverted;
                background-color: $color-primary;
                /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-label {
                    color: $color-secondary;
                }


            }

            &.mat-form-field-disabled {
                input {
                    color: $color-text-inactive;
                }
            }
        }
    }
}

.ngx-gauge-meter {
    &.gauge-m {
        .reading-block{
            line-height: 25px;
        }
    }
}

//--------------

.sub-text
{
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: gray;
}

.page-title {
    font-size: 40px;
    margin-bottom: 4px;

    .mat-badge-after .mat-badge-content {
        left: 103%;
    }

    .mat-badge-above .mat-badge-content {
        bottom: 70%;
    }

    .mat-badge-large .mat-badge-content {
        font-size: 20px;
    }

    .mat-badge-content {
        background-color: $color-alt2;
    }
}

.page-subtitle {
    font-size: 20px;
    font-weight: 400;
    color: $color-page-subtitle;
    margin-bottom: 30px;
}


// tailwind refactor, remove if the app is tailwind ready (removed all conflicting styles)
.font-semibold {
    font-weight: 600;
}

.gap-1\.5 {
    gap: 0.375rem;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-10 {
    gap: 2.5rem;
}

.break-inside-avoid {
    break-inside: avoid;
}

.break-after-avoid {
    break-after: avoid;
}

.m-auto {
    margin: auto;
}

.text-xs {      
    font-size: 0.75rem;     // 12px
    line-height: 1rem;      // 16px
}

.text-sm {
    font-size: 0.875rem;    // 14px
    line-height: 1.25rem;   // 20px
}

.text-3xl {
    font-size: 1.875rem;    // 30px
    line-height: 2.25rem;   // 36px
}

.mb-4 {
    margin-bottom: 1rem;    //16px
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.flex-wrap {
    flex-wrap: wrap;
}

.email-admin-dialog {
    min-width: 700px;
}

//--

@include media ("<1200px", 'screen') {
    .scroll-panel {
        margin-right: 0;
    }
}

@include media("<1000px", 'screen') {
    div.main-table {
        .t-row {
            flex-flow: wrap;
            
            .t-h {
                font-size: 12px;
            }
    
            .t-d, .t-h {
                &.mobile-flex-full {
                    flex: 0 0 100%;
                }

            }
        }
    }
}

@include media("<768px") {
    .w-full-hd {
        padding: 0 8px;
    }
    
    .report-container {
        padding-right: 5px;
        padding-left: 5px;
                
    }

    .page-title, .page-subtitle {
        padding-left: 16px; 
    }

    .hide-768 {
        display: none;
    }
   
    .container, .container-fluid {
        padding-right: 2px;
        padding-left: 2px;

        &.main-container {
            padding-top: 8px;
            .col-lg-4, .col-md-4, .col-md-6, .col-md-12, .col-lg-6 {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
    
    .action-buttons {                
        padding: 10px 20px;
        margin-top: 12px;
        display: flex;
        gap: 12px;

        &.mobile-page-bottom {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            border-top: 1px solid #e0e0e0;
            z-index: 1000;
        }
    }
   
}

@include media("<555px") {
    .page-title {
        font-size: 30px;
    }

    .page-subtitle {
        font-size: 15px; 
    }
}

@media print {
    
    .report-container {
        padding: 0 !important;
        margin: 0 !important;
        
        max-width: 100%;
        width: 100%;
                
        .report-content {
            padding: 0 24px;             
        }   
    }    
}