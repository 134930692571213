@import 'colors';
@import 'fonts';
@import 'space';
@import 'layout';

@import 'include-media';

@import 'material/mat-button';
@import 'material/mat-card';
@import 'material/mat-form-field';
@import 'material/mat-tab-group';
@import 'material/mat-table';


html {
  --mdc-filled-button-label-text-tracking: 0;
  --mdc-text-button-label-text-tracking: 0;
  --mat-form-field-container-text-size: 14px;
  --mdc-text-button-label-text-color: eee;
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-tracking: 0;
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-color: #0000008a;
  --mdc-radio-state-layer-size: 28px;
  --mdc-outlined-text-field-label-text-size: 14px;
  --mat-select-trigger-text-size: 14px;
  --mat-option-label-text-size: 14px;

  --mat-dialog-actions-padding: 0 24px 24px 24px;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mat-form-field-state-layer-color: none;
}

// snackbar

.mat-mdc-snack-bar-container {
  color: rgb(255, 255, 255, 0.8);
  background-color: $color-secondary;

  &.snackbar-success {
      background-color: $color-success;
  }

  &.snackbar-info {
    background-color: $color-info;
  }

  &.snackbar-warning {
    background-color: $color-warning;
  }
}


// .mat-mdc-unelevated-button>.mat-icon.normal {
//   font-size: 24px;
//   height: 24px;
//   width: 24px;
//   margin-right: auto;
//   margin-left: auto;
// }

// extension panel

.mat-expansion-panel {
  &.default {
    margin-bottom: $space-lg;
    padding: 6px;
    background-color: $color-primary;
  
    .mat-expansion-panel-header {
      color: white;
    }
  
    .mat-expansion-panel-body {
      padding: $space-lg;
    }
  
    .mat-expansion-panel-content {
      background-color: white;
    }
  
    .mat-expansion-indicator::after {
      color: white;
    }
  }

  &.lg-panel {
    background-color: #f4f4f4;
    &.style-primary {
      background-color: $color-primary;

      .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
        color: white;
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-label {
          font-weight: 700;
          font-size: 18px;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-mdc-checkbox:not(.mat-checkbox-disabled) {
          /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
          .mat-checkbox-frame{
            border-color: $color-alt1;
          }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-disabled .mat-checkbox-label
        {
          color: #828282;
        }
      }
    }

    .mat-expansion-panel-header {
      font-size: 22px;
      padding: 15px 24px;
    }

    .mat-expansion-panel-header-description {
      flex-grow: 0.11;
    }
  }

  &.expansion-panel-review {
    padding: 0;

    .mat-expansion-panel-header {
      padding: 24px;
      height: auto;
      box-shadow: 0 5px 5px -3px rgb(0 0 0 / 10%), 0 8px 10px 1px rgb(0 0 0 / 1%), 0 3px 14px 2px rgb(0 0 0 / 8%);
    }

    .mat-content {
      display: flex;
      gap: 16px;
      margin: 0;
    }

    .mat-expansion-panel-body{
      padding: 0;
    }
  }

}




//.mat-input-element {
  //height: 20px;
//}


// mat tab

.mat-mdc-tab-group {
  &.hide-labels .mat-mdc-tab-header {
    display: none;
  }

  &.no-scroll-y {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-body-content {
      overflow-y: hidden;
    }
  }
}

// mat-select

.mat-mdc-select {
  &.selected-text-lg {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-trigger {
      height: 2.125em;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value-text {
      font-size: 2em;      
      font-weight: 600;
    }
  }  

  &.select-multi-line {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value-text {
      white-space: normal;      
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-trigger {
      height: 100%;
    }
  }
}

.mat-mdc-selection-list.mat-mdc-list-base {
  &.custom {
    /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version.*/
    .mat-mdc-list-item, .mat-list-option {
      min-height: 48px;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 2px solid #fff;
    }
  }
}

//mat-option

.mat-mdc-option {
  &.multi-line {
    white-space: normal;
    line-height: 20px;
    height: auto;
    padding: 6px 16px;
    border: 1px solid #eee;
    font-size: 14px;
  }

  &.mdc-list-item
  {
    border-top: 1px solid #EEE;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

//mat-mdc-dialog
.mat-mdc-dialog-container .mat-mdc-dialog-title+.mat-mdc-dialog-content {
  padding-top: 8px !important;
}

.mat-icon {
  &.info {
    color: $color-info;
  }
}

@include media("<768px") {
  .mat-expansion-panel { 
    &.expansion-panel-review {
  
      .mat-expansion-panel-header {
        padding: $space-m;
      }
  
  
    }
  }

  .card-review, .expansion-panel-review { 
      .card-actions { 
          button {
              width: 132px;
          }
      }
  }
}

@include media("<555px") {
  .mat-expansion-panel {
    &.expansion-panel-review {
      .mat-content {
        flex-wrap: wrap;
      }
    }
  }
}