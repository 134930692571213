@import 'include-media';

.mat-mdc-unelevated-button {
    &.icon-button {
      min-width: 0;
      padding: 0 10px;    
  
      &.bg-default {
        background-color: white;
        color: black;
        border: 1px solid $color-border;
      }
  
      &.bg-success {
        color: white;      
      }
    }

    & > .mat-icon.no-margin {
      margin: 0;
    }

    &:not(:disabled) {
      &.bg-alt2 {
        background-color: $color-alt2;
        color: white;
      }

      &.bg-alt1 {
        background-color: $color-alt1;
        color: white;
      }
    }

    &.review-button {
      padding: 26px 36px;
      text-align: left;
      text-transform: uppercase;

      &.bt-long-text {
        padding: 26px 26px;
      }

      &.detail-button {
        padding: 20px 24px;
      }
    }
  }

  .mat-mdc-icon-button {
      &.icon-bt-md {          
          .mat-icon {
              font-size: 16px;
              line-height: 20px;
              height: 20px;
              width: 20px;
          }
      }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-group.icons {
    border: none;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle-appearance-standard {
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-label-content {
        padding: 0 6px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-label-content {
        line-height: 34px;
      }
    }
  }

  @include media("<768px") {
    .mat-mdc-unelevated-button {
      &.review-button { 
        &.bt-long-text {
          padding: 24px 16px;
        }

        &.detail-button {
          padding: 20px 14px;
        }

      }
    }
  }
