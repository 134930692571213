@use 'colors';

$panel-header-bk: #3a3a50;


.top-panel {
    background-color: #f5f6f9;
    height: 30px;
}

.form-control {
    background-color: #f5f6f9;
    font-size: 13px;
    border: 1px solid #cecece;
    border-radius: 0;
}

.panel-primary {
    border-color: #3a3a50;

    h4 {
        color: #eee;
    }

    .panel {
        border-radius: 0;
    }

    & > .panel-heading {
        color: #fff;
        background-color: $panel-header-bk;
        border-color: #3a3a50;
    }

    & > .panel-body {
        background-color: $panel-header-bk;
    }
}

.panel-info {
    & > .panel-heading {
        color: #3a3a50;
        background-color: #ddd;
        border-color: #cecece;
    }

    & .panel-body {
        background-color: #ddd;
    }
}

.question {
    border-radius: 0;

    strong {
        color: #ff3a5f;
    }
}

.alert-danger {
    color: #ff3a5f;
    border-color: #ff3a5f;
}

.dropdown-menu {
    button {
        width: 100%;
    }

    button:not(:first-child) {
        margin-top: 2px;
    }
}

a, a:focus, a:hover {
    color: #ff3a5f;
}

a.secondary {
    color: colors.$color-text;
    &:hover {
        color: colors.$color-secondary;
    }    
    text-decoration: none;
}

.padding-left-30 {
    padding-left: 30px;
}

.ul-style-1 {
    list-style: none;
    padding-left: 0;
    > li {
        padding-left: 12px;
        border-left: 2px solid colors.$color-alt2;
        margin-bottom: 10px;
    }
}

.ul-style-2 {        
    li {
        padding-left: 12px;        
        margin-bottom: 10px;
    }
}

.mat-mdc-progress-bar {
    &.default-progress {
        border-radius: 5px;        
        height: 10px;
        /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
        .mat-progress-bar-fill::after {
            background-color: colors.$color-progress-bar;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
        .mat-progress-bar-buffer {
            background-color: colors.$color-progress-bar-bg;
        }
    }
    
}

.guide-title {
    margin-bottom: 40px;
    font-size: 2.25em;
    line-height: 1.3em;
}