@import 'space';
@import 'colors';

.text-lg {
    font-size: $space-lg;
}

.text-m {
    font-size: $space-m;
}

.text-underline {
    text-decoration: underline;
}

.text-10 {
    font-size: 10px;
    line-height: 14px;
}


.text-12 {
    font-size: 12px;
    line-height: 16px;
}

.text-14 {
    font-size: 14px;
    line-height: 20px;
}

.text-16 {
    font-size: 16px;
    line-height: 22px;
}

.text-18 {
    font-size: 18px;
    line-height: 24px;
}

.text-20 {
    font-size: 20px;
    line-height: 28px;
}

.text-24 {
    font-size: 24px;
    line-height: 28px;
}

.text-34 {
    font-size: 34px;
    line-height: 40px;
}

.font-secondary {
    font-family: 'Playfair Display', serif;
    font-weight: 500;
}

.line-index {
    color: $color-alt1;
    font-weight: 800;
    &.group-level-1 {
        font-size: 30px;
      }

      &.group-level-2 {
        font-size: 27px;
      }

      &.group-level-3 {
        font-size: 24px;
      }
}

// em
.text-em-0-75 {
    font-size: 0.75em;
    line-height: 0.975em;
}

.text-em-1 {
    font-size: 1em;
    line-height: 1.3em;
}

.text-em-1-125 {
    font-size: 1.125em;
    line-height: 1.45em;
}

.text-em-1-25 {
    font-size: 1.25em;
    line-height: 1.625em;
}

.text-em-1-5 {
    font-size: 1.5em;
    line-height: 1.95em;
}

.text-em-2-25 {
    font-size: 2.25em;
    line-height: 2.925em;
}